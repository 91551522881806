import React, {useState, useEffect} from 'react';
import {getSettings} from '../../services/SettingsService';

function TelegramChat() {

    const [url, setUrl] = useState('');

    function getFullUrl(settings) {
        return `https://api.telegram.org/${settings.telegramToken}/getUpdates`;
        // return `https://t.me/${settings.telegramToken}/getUpdates`;
    }

    useEffect(() => {
        getSettings()
            .then(settings => setUrl(getFullUrl(settings)))
            .catch(err => console.error(err))
    }, []);

    return <iframe src={url} title='Telegram Chats' width='100%'></iframe>
}

export default TelegramChat;