import React, { useRef, useState, useEffect } from 'react';
import ActionMessage from '../../components/ActionMessage/ActionMessage';
import SwitchInput from '../../components/SwitchInput/SwitchInput';
import { processError } from '../../services/ServiceUtils';
import { saveLimit } from '../../services/LimitsService';
import { getValueOrDefault, initializeModal } from '../../utils/ModalUtils';

/**
 * props:
 * - data
 * - onSubmit
 */
function LimitModal(props) {

    const DEFAULT_LIMIT = {
        name: '',
        maxAutomations: 0,
        maxMonitors: 0,
        maxBacktests: 0,
        isActive: false
    };

    const [error, setError] = useState('');
    const [limit, setLimit] = useState(DEFAULT_LIMIT);

    const btnClose = useRef('');

    useEffect(() => {
        setLimit(props.data);
    }, [props.data]);

    useEffect(() => {
        initializeModal('modalLimit', () => setLimit({ ...DEFAULT_LIMIT }));
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    function onSubmit(event) {
        saveLimit(limit.id, limit)
            .then(result => {
                btnClose.current.click();
                if (props.onSubmit) props.onSubmit(result);
            })
            .catch(err => setError(processError(err)))
        props.onSubmit(event);
    }

    function onInputChange(event) {
        setLimit(prevState => ({ ...prevState, [event.target.id]: event.target.value }));
    }
    
    return (
        <div className="modal fade" id="modalLimit" tabIndex="-1" role="dialog" aria-labelledby="modalTitleNotify" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <p className="modal-title" id="modalTitleNotify">{props.data.id ? 'Edit ' : 'New '}Limit</p>
                        <button ref={btnClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
                    </div>

                    <div className="modal-body">
                        <div className="form-group">
                            <div className='row'>
                                <div className='col-12 mb-3'>
                                    <div className='form-group'>
                                        <label htmlFor='name'>Name:</label> 
                                        <input type='text' id='name' className='form-control' onChange={onInputChange} value={getValueOrDefault(limit.name)} placeholder="Limit's name" />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-4 col-sm-6 mb-3'>
                                    <div className='form-group mb-4'>
                                        <label htmlFor='maxAutomations'>Max. Automations:</label>
                                        <input type='number' id='maxAutomations' className='form-control' onChange={onInputChange} value={getValueOrDefault(limit.maxAutomations)} placeholder="0" />
                                    </div>
                                </div>
                                <div className='col-md-4 col-sm-6 mb-3'>
                                    <div className='form-group mb-4'>
                                        <label htmlFor='maxMonitors'>Max. <br />Monitors:</label>
                                        <input type='number' id='maxMonitors' className='form-control' onChange={onInputChange} value={getValueOrDefault(limit.maxMonitors)} placeholder="0" />
                                    </div>
                                </div>
                                <div className='col-md-4 col-sm-6 mb-3'>
                                    <div className='form-group mb-4'>
                                        <label htmlFor='maxBacktests'>Max. Backtests/mo.:</label>
                                        <input type='number' id='maxBacktests' className='form-control' onChange={onInputChange} value={getValueOrDefault(limit.maxBacktests)} placeholder="0" />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 mb-3'>
                                    <div className='form-group'>
                                        <SwitchInput id='isActive' text='Is Active?' onChange={onInputChange} isChecked={limit.isActive} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <ActionMessage error={error} extraClassName="mt-1 col-9 py-1" />
                            <button type="button" className="btn btn-sm btn-primary" onClick={onSubmit}>
                                <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" /></svg>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default LimitModal;