import React from 'react';
import RowLogButton from '../../components/ActionButtons/RowLogButton';

/**
 * props:
 * - data
 * - onViewClick
 */
function LogRow(props) {

    return (
        <tr>
            <td>
                {props.data}
            </td>
            <td>
                <RowLogButton id={props.data} title='View this Log' onClick={props.onViewClick} />
            </td>
        </tr>
    );
}

export default LogRow;