// import { processError } from '../services/ServiceUtils';

export function getErrorMessageFromResponse(error, stringifyData = false) {
    return error.response ? (stringifyData ? JSON.stringify(error.response.data) : error.response.data) : error.message;
}

export function processWebSocketError(event) {
    console.error(event);
    console.error(event.error);


    // console.error(event.target.error);
    // const message = JSON.stringify(error);

    // const message = processError(event);
    // const message = JSON.stringify(event, ["message", "arguments", "type", "name"]);

    return getErrorMessage('Erro ao contectar WebSocket');
}

export const messageTypes = {
    SUCCESS: 'success',
    ERROR: 'error',
    INFO: 'info'
};

export function getMessage(type, text) {
    return { type, text };
}

export function getSuccessMessage(text) {
    return getMessage(messageTypes.SUCCESS, text);
}

export function getErrorMessage(text) {
    return getMessage(messageTypes.ERROR, text);
}

export function getInfoMessage(text) {
    return getMessage(messageTypes.INFO, text);
}

export function getEmptyMessage() {
    return getMessage('', '');
}

export function getErrorMessageFromErrorObject(err) {
    return getErrorMessage(getErrorMessageFromResponse(err));
}

