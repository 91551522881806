import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import Menu from '../../components/Menu/Menu';
import Pagination from '../../components/Pagination/Pagination';
import Toast from '../../components/Toast/Toast';
import { processError } from '../../services/ServiceUtils';
import { deleteUser, getUsers, startUser, stopUser } from '../../services/UsersService';
import { findRowFromActionButton, getIdFromActionButton } from '../../utils/DataRowButtonUtils';
import { getEmptyMessage, getErrorMessage, messageTypes } from '../../utils/MessageUtils';
import NewUserButton from './NewUserButton';
import SearchUser from './SearchUser';
import UserModal from './UserModal/UserModal';
import UserRow from './UserRow';

function Users() {

    const defaultLocation = useLocation();
    const history = useHistory();

    const [users, setUsers] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(getPage());
    const [search, setSearch] = useState('');
    const [editUser, setEditUser] = useState({});

    const [notification, setNotification] = useState(getEmptyMessage());

    function getPage(location) {
        if (!location) location = defaultLocation;
        return new URLSearchParams(location.search).get('page');
    }

    useEffect(() => {
        return history.listen(location => {
            setPage(getPage(location));
        });
    }, [history]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getUsers(search, page || 1)
            .then(result => {
                setUsers(result.rows);
                setCount(result.count);
            })
            .catch(err => setNotification(getErrorMessage(processError(err))))
    }, [page, search]);

    function onEditClick(event) {
        setEditUser(findRowFromActionButton(event, users));
    }

    function onStopClick(event) {
        stopUser(getIdFromActionButton(event))
            .then(result => verifyResult(result))
            .catch(err => setNotification(getErrorMessage(processError(err))))
    }

    function onStartClick(event) {
        startUser(getIdFromActionButton(event))
            .then(result => verifyResult(result))
            .catch(err => setNotification(getErrorMessage(processError(err))))
}

    function onDeleteClick(event) {
        deleteUser(getIdFromActionButton(event))
            .then(result => verifyResult(result))
            .catch(err => setNotification(getErrorMessage(processError(err))))
    }

    function onSearchChange(event) {
        setSearch(event.target.value);
    }

    function verifyErrorNotification(result) {
        if (!result.notifications) return false;
        const errorMessage = result.notifications.find(n => n.type === messageTypes.ERROR);
        if (errorMessage) {
            setNotification(errorMessage);
            return true;
        }

        return false;
    }

    function verifyResult(result) {
        if (!verifyErrorNotification(result))
            history.go(0);
    }

    function onModalSubmit(result) {
        verifyResult(result);
    }

    return (
        <React.Fragment>
            <Menu />
            <main className='content'>
                <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
                    <div className='d-block mb-4 mb-md-0'>
                        <h2 className='h4'>Users</h2>
                    </div>
                    <div className='btn-toolbar mb-2 mb-md-0'>
                        <div className='d-inline-flex align-items-center'>
                            <NewUserButton />
                            <SearchUser placeholder={search} onChange={onSearchChange} />
                        </div>
                    </div>
                </div>
                <div className='card card-body border-0 shadow table-wrapper table-responsive'>
                    <table className='table table-hover'>
                        <thead>
                            <tr>
                                <th className='border-gray-200'>Name</th>
                                <th className='border-gray-200'>Email</th>
                                <th className='border-gray-200'>Limit</th>
                                <th className='border-gray-200'>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                users && users.length
                                ? users.map(user => (
                                    <UserRow key={user.id} data={user} onEditClick={onEditClick} onStopClick={onStopClick} onStartClick={onStartClick} onDeleteClick={onDeleteClick} />
                                ))
                                : <React.Fragment></React.Fragment>
                            }
                        </tbody>
                    </table>
                    <Pagination count={count} />
                </div>
                <Footer />
            </main>
            <UserModal data={editUser} onSubmit={onModalSubmit} />
            <Toast type={notification.type} text={notification.text} />
        </React.Fragment>
    )
}

export default Users;