import React, { useEffect, useState, useRef } from 'react';
import { getSettings, updateSettings, doTestEmail, doTestSms, doTestTelegram } from '../../services/SettingsService';
import { processError } from '../../services/ServiceUtils';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import Toast from '../../components/Toast/Toast';
import LogModal from '../../components/Logs/LogModal';
import { getEmptyMessage, getErrorMessage, getSuccessMessage, getErrorMessageFromResponse } from '../../utils/MessageUtils';
import { doTestApiUrl, doTestStreamUrl } from '../../services/ExchangeService';
import { getValueOrDefault } from '../../utils/ModalUtils';

function Settings() {

    const inputConfirmPassword = useRef('');
    const [settings, setSettings] = useState({});

    const [notification, setNotification] = useState(getEmptyMessage());

    useEffect(() => {
        getSettings()
            .then(settings => {
                delete settings.password;
                delete settings.secretKey;
                setSettings(settings);
            })
            .catch(err => setNotification(getErrorMessage(processError(err))))
    }, []);

    function onInputChange(event) {
        setSettings(prevState => ({ ...prevState, [event.target.id]: event.target.value }));
    }

    function onFormSubmit(event) {

        if ((settings.password || inputConfirmPassword.current.value)
            && settings.password !== inputConfirmPassword.current.value) {
            return setNotification(getErrorMessage(`The fields New Password and Confirm Password must be equals.`));
        }

        updateSettings(settings)
            .then(result => {
                if (result)
                    setNotification(getSuccessMessage(`Settings updated successfully!`));
                else
                    setNotification(getErrorMessage(`Can't update the settings.`));
            })
            .catch(err => setNotification(getErrorMessage(`Can't update the settings.`)))
    }

    function getTestErrorMessage(err) {
        const errorMessage = getErrorMessageFromResponse(err);
        if (errorMessage.text && errorMessage.text.code && errorMessage.text.msg)
            errorMessage.text = `${errorMessage.text.msg} (code ${errorMessage.text.code})`;
        return errorMessage;
    }

    async function testBinanceApiUrl(event) {
        await doTestApiUrl(settings.apiUrl, settings.accessKey, settings.secretKey)
            .then(result => setNotification(result))
            .catch(err => setNotification(getTestErrorMessage(err)));
    }

    function testBinanceStreamUrl(event) {
        doTestStreamUrl(settings.streamUrl)
            .then(result => setNotification(result))
            .catch(err => setNotification(getTestErrorMessage(err)));
    }

    function testEmail(event) {
        doTestEmail()
            .then(result => setNotification(result))
            .catch(err => setNotification(getErrorMessage(processError(err))))
    }

    function testSms(event) {
        doTestSms()
            .then(result => setNotification(result))
            .catch(err => setNotification(getErrorMessage(processError(err))))
    }

    function testTelegram(event) {
        doTestTelegram()
            .then(result => setNotification(result))
            .catch(err => setNotification(getErrorMessage(processError(err))))
    }

    return (
        <React.Fragment>
            <Menu />
            <main className="content">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <h1 className="h4">Settings</h1>
                    </div>
                    <div className='btn-toolbar mb-2 mb-md-0'>
                        <div className='d-inline-flex align-items-center'>
                            <button id='btnLogsSystem' className='btn btn-primary animate-up-2 me-2' data-bs-toggle='modal' data-bs-target='#modalLogsSystem'>
                                <svg className="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2h-1.528A6 6 0 004 9.528V4z" /><path fillRule="evenodd" d="M8 10a4 4 0 00-3.446 6.032l-1.261 1.26a1 1 0 101.414 1.415l1.261-1.261A4 4 0 108 10zm-2 4a2 2 0 114 0 2 2 0 01-4 0z" clipRule="evenodd" /></svg>
                                System Logs
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">

                        <ul className='nav nav-tabs' id='tabs' role='tablist'>
                            <li className='nav-link' role='presentation'>
                                <button className='nav-link active' id='memory-tab' data-bs-toggle='tab' data-bs-target='#personalSettingsTab' type='button' role='tab'>
                                    Admin Settings
                                </button>
                            </li>
                            <li className='nav-link' role='presentation'>
                                <button className='nav-link' id='brain-tab' data-bs-toggle='tab' data-bs-target='#alertSettingsTab' type='button' role='tab'>
                                    Alert Settings
                                </button>
                            </li>
                            <li className='nav-link' role='presentation'>
                                <button className='nav-link' id='agenda-tab' data-bs-toggle='tab' data-bs-target='#binanceSettingsTab' type='button' role='tab'>
                                    Binance Settings
                                </button>
                            </li>
                        </ul>

                        <div className='tab-content px-3 mb-3' id='tabContent'>
                            <div className='tab-pane fade show active pt-3' id='personalSettingsTab' role='tabpanel'>
                                <div className="card card-body border-0 shadow mb-4">

                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <div className="form-group">
                                                <label htmlFor="email">Email</label>
                                                <input className="form-control" id="email" type="email" placeholder="name@company.com" value={getValueOrDefault(settings.email)} onChange={onInputChange} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <div className="form-group">
                                                <label htmlFor="phone">Cellphone</label>
                                                <input className="form-control" id="phone" type="tel" placeholder="+5551123456789" value={getValueOrDefault(settings.phone)} onChange={onInputChange} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <div className="form-group">
                                                <label htmlFor="newPassword">New Password</label>
                                                <input className="form-control" id="password" type="password" placeholder="Enter your new password" onChange={onInputChange} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <div className="form-group">
                                                <label htmlFor="confirmPassword">Confirm Password</label>
                                                <input ref={inputConfirmPassword} className="form-control" id="confirmPassword" type="password" placeholder="Your new password again" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap">
                                            <div className="col-sm-3">
                                                <button className="btn btn-gray-800 mt-2 animate-up-2" type="button" onClick={onFormSubmit}>
                                                    <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" /></svg>
                                                    Save
                                                </button>
                                            </div>

                                            <div className='btn-toolbar mb-2 mb-md-0'>
                                                <div className='d-inline-flex align-items-center'>
                                                    <button id='btnLogsAuth' className='btn btn-primary animate-up-2 me-2' data-bs-toggle='modal' data-bs-target='#modalLogsAuth'>
                                                        <svg className="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2h-1.528A6 6 0 004 9.528V4z" /><path fillRule="evenodd" d="M8 10a4 4 0 00-3.446 6.032l-1.261 1.26a1 1 0 101.414 1.415l1.261-1.261A4 4 0 108 10zm-2 4a2 2 0 114 0 2 2 0 01-4 0z" clipRule="evenodd" /></svg>
                                                        Access Logs
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='tab-pane fade pt-3' id='alertSettingsTab' role='tabpanel'>
                                <div className="card card-body border-0 shadow mb-4">

                                    <div className="row">
                                        <div className="card-header bg-gray h6 p-1 mb-3">SendGrid</div>
                                        <div className="col-sm-6 mb-3">
                                            <div className="form-group">
                                                <label htmlFor="sendGridApiKey">SendGrid API Key</label>
                                                <input className="form-control" id="sendGridApiKey" type="password" placeholder="Enter the SendGrid API Key" value={getValueOrDefault(settings.sendGridKey)} onChange={onInputChange} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="card-header bg-gray h6 p-1 mb-3">Twilio</div>
                                        <div className="col-md-4 mb-3">
                                            <div className="form-group">
                                                <label htmlFor="twilioPhone">Twilio Phone</label>
                                                <input className="form-control" id="twilioPhone" type="tel" placeholder="+1123456789" value={getValueOrDefault(settings.twilioPhone)} onChange={onInputChange} />
                                            </div>
                                        </div>
                                        <div className="col-sm-4 mb-3">
                                            <div className="form-group">
                                                <label htmlFor="twilioSid">Twilio SID</label>
                                                <input className="form-control" id="twilioSid" type="text" placeholder="Enter the Twilio SID" value={getValueOrDefault(settings.twilioSid)} onChange={onInputChange} />
                                            </div>
                                        </div>
                                        <div className="col-sm-4 mb-3">
                                            <div className="form-group">
                                                <label htmlFor="twilioToken">Twilio Token</label>
                                                <input className="form-control" id="twilioToken" type="password" placeholder="Enter the Twilio Token" value={getValueOrDefault(settings.twilioToken)} onChange={onInputChange} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="card-header bg-gray h6 p-1 mb-3">Telegram</div>
                                        <div className="col-sm-4 mb-3">
                                            <div className="form-group">
                                                <label htmlFor="telegramBot">Telegram Bot Name</label>
                                                <input className="form-control" id="telegramBot" type="text" placeholder="Enter the Telegram Bot Name" value={getValueOrDefault(settings.telegramBot)} onChange={onInputChange} />
                                            </div>
                                        </div>
                                        <div className="col-sm-4 mb-3">
                                            <div className="form-group">
                                                <label htmlFor="telegramToken">Telegram Bot Token</label>
                                                <input className="form-control" id="telegramToken" type="password" placeholder="Enter the Telegram Bot Token" value={getValueOrDefault(settings.telegramToken)} onChange={onInputChange} />
                                            </div>
                                        </div>
                                        <div className="col-sm-4 mb-3">
                                            <div className="form-group">
                                                <label htmlFor="telegramChat">Telegram Chat ID</label><a href={'https://t.me/' + settings.telegramBot} target="_blank" rel="noreferrer" className='badge bg-secondary py-1 ms-1'>?</a>
                                                <input className="form-control" id="telegramChat" type="text" placeholder="Enter the Telegram Chat ID" value={getValueOrDefault(settings.telegramChat)} onChange={onInputChange} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap">
                                            <div className="col-sm-3">
                                                <button className="btn btn-gray-800 mt-2 animate-up-2" type="button" onClick={onFormSubmit}>
                                                    <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" /></svg>
                                                    Save
                                                </button>
                                            </div>
                                            <div className='btn-toolbar mb-2 mb-md-0'>
                                                <button className="btn btn-gray-800 mt-2 mr-2 animate-up-2 me-2" type="button" onClick={testEmail}>
                                                    <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                                        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                                    </svg>
                                                    Test Email
                                                </button>
                                                <button className="btn btn-gray-800 mt-2 mr-2 animate-up-2 me-2" type="button" onClick={testSms}>
                                                    <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" d="M7 2a2 2 0 00-2 2v12a2 2 0 002 2h6a2 2 0 002-2V4a2 2 0 00-2-2H7zm3 14a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                                                    </svg>
                                                    Test SMS
                                                </button>
                                                <button className="btn btn-gray-800 mt-2 animate-up-2" type="button" onClick={testTelegram}>
                                                    <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clipRule="evenodd" />
                                                    </svg>
                                                    Test Telegram
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='tab-pane fade pt-3' id='binanceSettingsTab' role='tabpanel'>
                                <div className="card card-body border-0 shadow mb-4">

                                    <div className="row">
                                        <div className="col-sm-12 mb-3">
                                            <div className="form-group">
                                                <label htmlFor="apiUrl">API URL</label>
                                                <input className="form-control" id="apiUrl" type="text" placeholder="Enter the API URL" value={getValueOrDefault(settings.apiUrl)} onChange={onInputChange} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-12 mb-3">
                                            <div className="form-group">
                                                <label htmlFor="streamUrl">STREAM URL</label>
                                                <input className="form-control" id="streamUrl" type="text" placeholder="Enter the STREAM URL" value={getValueOrDefault(settings.streamUrl)} onChange={onInputChange} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap">
                                            <div className="col-sm-3">
                                                <button className="btn btn-gray-800 mt-2 animate-up-2" type="button" onClick={onFormSubmit}>
                                                    <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" /></svg>
                                                    Save
                                                </button>
                                            </div>

                                            <div className='btn-toolbar mb-2 mb-md-0'>

                                                <button className="btn btn-gray-800 mt-2 mr-2 animate-up-2 me-2" type="button" onClick={testBinanceApiUrl}>
                                                    <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clipRule="evenodd" /></svg>
                                                    Test API URL
                                                </button>

                                                <button className="btn btn-gray-800 mt-2 mr-2 animate-up-2 me-2" type="button" onClick={testBinanceStreamUrl}>
                                                    <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clipRule="evenodd" /></svg>
                                                    Test Stream URL
                                                </button>

                                                <button id='btnLogsBinance' className='btn btn-gray-800 mt-2 mr-2 animate-up-2 me-2' data-bs-toggle='modal' data-bs-target='#modalLogsBinance'>
                                                    <svg className="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2h-1.528A6 6 0 004 9.528V4z" /><path fillRule="evenodd" d="M8 10a4 4 0 00-3.446 6.032l-1.261 1.26a1 1 0 101.414 1.415l1.261-1.261A4 4 0 108 10zm-2 4a2 2 0 114 0 2 2 0 01-4 0z" clipRule="evenodd" /></svg>
                                                    Binance Logs
                                                </button>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* 
                <div className="row">
                    <div className="col-12">
                        <div className="card card-body border-0 shadow mb-4">
                            <h2 className="h5 mb-4">Personal Settings</h2>

                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input className="form-control" id="email" type="email" placeholder="name@company.com" value={getValueOrDefault(settings.email)} onChange={onInputChange} />
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="phone">Cellphone</label>
                                        <input className="form-control" id="phone" type="tel" placeholder="+5551123456789" value={getValueOrDefault(settings.phone)} onChange={onInputChange} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="newPassword">New Password</label>
                                        <input className="form-control" id="password" type="password" placeholder="Enter your new password" onChange={onInputChange} />
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="confirmPassword">Confirm Password</label>
                                        <input ref={inputConfirmPassword} className="form-control" id="confirmPassword" type="password" placeholder="Your new password again" />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap">
                                    <div className="col-sm-3">
                                        <button className="btn btn-gray-800 mt-2 animate-up-2" type="button" onClick={onFormSubmit}>
                                        <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" /></svg>
                                        Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card card-body border-0 shadow mb-4">
                            <h2 className="h5 my-4">Alert Settings</h2>
                            <div className="row">
                                <div className="col-sm-12 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="sendGridApiKey">SendGrid API Key</label>
                                        <input className="form-control" id="sendGridApiKey" type="password" placeholder="Enter the SendGrid API Key" value={getValueOrDefault(settings.sendGridKey)} onChange={onInputChange} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="twilioPhone">Twilio Phone</label>
                                        <input className="form-control" id="twilioPhone" type="tel" placeholder="+1123456789" value={getValueOrDefault(settings.twilioPhone)} onChange={onInputChange} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="twilioSid">Twilio SID</label>
                                        <input className="form-control" id="twilioSid" type="text" placeholder="Enter the Twilio SID" value={getValueOrDefault(settings.twilioSid)} onChange={onInputChange} />
                                    </div>
                                </div>
                                <div className="col-sm-12 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="twilioToken">Twilio Token</label>
                                        <input className="form-control" id="twilioToken" type="password" placeholder="Enter the Twilio Token" value={getValueOrDefault(settings.twilioToken)} onChange={onInputChange} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="telegramBot">Telegram Bot Token</label>
                                        <input className="form-control" id="telegramBot" type="password" placeholder="Enter the Telegram Bot Token" value={getValueOrDefault(settings.telegramBot)} onChange={onInputChange} />
                                    </div>
                                </div>
                                <div className="col-sm-12 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="telegramChat">Telegram Chat ID</label>
                                        <input className="form-control" id="telegramChat" type="text" placeholder="Enter the Telegram Chat ID" value={getValueOrDefault(settings.telegramChat)} onChange={onInputChange} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap">
                                    <div className="col-sm-3">
                                        <button className="btn btn-gray-800 mt-2 animate-up-2" type="button" onClick={onFormSubmit}>
                                        <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" /></svg>
                                        Save
                                        </button>
                                    </div>
                                    <div className='btn-toolbar mb-2 mb-md-0'>
                                        <button className="btn btn-gray-800 mt-2 mr-2 animate-up-2 me-2" type="button" onClick={testEmail}>
                                            <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                            </svg>
                                            Test Email
                                        </button>
                                        <button className="btn btn-gray-800 mt-2 mr-2 animate-up-2 me-2" type="button" onClick={testSms}>
                                            <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M7 2a2 2 0 00-2 2v12a2 2 0 002 2h6a2 2 0 002-2V4a2 2 0 00-2-2H7zm3 14a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                                            </svg>
                                            Test SMS
                                        </button>
                                        <button className="btn btn-gray-800 mt-2 animate-up-2" type="button" onClick={testTelegram}>
                                            <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clipRule="evenodd" />
                                            </svg>
                                            Test Telegram
                                        </button>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card card-body border-0 shadow mb-4">
                            <h2 className="h5 my-4">Binance Settings</h2>
                            <div className="row">
                                <div className="col-sm-12 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="apiUrl">API URL</label>
                                        <input className="form-control" id="apiUrl" type="text" placeholder="Enter the API URL" value={getValueOrDefault(settings.apiUrl)} onChange={onInputChange} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="streamUrl">STREAM URL</label>
                                        <input className="form-control" id="streamUrl" type="text" placeholder="Enter the STREAM URL" value={getValueOrDefault(settings.streamUrl)} onChange={onInputChange} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="accessKey">Access Key</label>
                                        <input className="form-control" id="accessKey" type="text" placeholder="Enter the API Access Key" value={getValueOrDefault(settings.accessKey)} onChange={onInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="secretKey">New Secret Key</label>
                                        <input className="form-control" id="secretKey" type="password" placeholder="Enter your new API Secret Key" onChange={onInputChange} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap">
                                    <div className="col-sm-3">
                                        <button className="btn btn-gray-800 mt-2 animate-up-2" type="button" onClick={onFormSubmit}>
                                        <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" /></svg>
                                        Save
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div> */}

                <Footer />
            </main>
            <Toast type={notification.type} text={notification.text} />
            <LogModal file="system" logName="System" modalId="modalLogsSystem" />
            <LogModal file="auth" logName="Auth" modalId="modalLogsAuth" />
            <LogModal file="binance" logName="Binance" modalId="modalLogsBinance" />
        </React.Fragment>
    )
}

export default Settings;