import { getBaseUrl } from './ServiceUtils';
import appRequest from './appRequest';

const AUTH_URL = getBaseUrl();

export async function doLogin(email, password) {
    return await appRequest.doPostRequest(`${AUTH_URL}/login`, {email, password});
}

export async function doLogout() {
    return await appRequest.doPostRequest(`${AUTH_URL}/logout`, {});
}