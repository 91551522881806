const DEFAULT_SYMBOL = 'BTCUSDT';

function isWildcard(symbol) {
    if (!symbol) return false;
    return symbol.startsWith('*');
}

function getQuoteFromWildcard(symbol) {
    if (!symbol) return symbol;
    return symbol.replace('*', '');
}

function getWildcardSymbolObject(symbol) {
    return { 
        symbol, 
        base: '*', 
        quote: getQuoteFromWildcard(symbol) 
    };
}

module.exports = {
    DEFAULT_SYMBOL,
    isWildcard,
    getQuoteFromWildcard,
    getWildcardSymbolObject
}
