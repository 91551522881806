import React, { useState, useEffect } from "react";
import { getActiveLimits, getAllLimits } from "../../../services/LimitsService";
import { processError } from "../../../services/ServiceUtils";

/**
 * props:
 * - id
 * - onChange
 */
function SelectLimit(props) {

    const [limits, setLimits] = useState('LOADING');

    useEffect(() => {
        let promise;
        const editing = (props.id > 0);
        if (editing) {
            promise = getAllLimits();
        }
        else {
            promise = getActiveLimits();
        }

        promise
            .then(limitObjects => setLimits(limitObjects))
            .catch(err => {
                processError(err);
                setLimits(['ERROR']);
            })

    }, [props.id]);

    return (
        <React.Fragment>
        <select id ='limitId' className="form-select" value={props.id} onChange={props.onChange}>
            <option value=''>Select...</option>
            {
                limits && limits.length > 0 && limits.map
                ? limits.map(limit => (<option key={limit.id} value={limit.id}>{limit.name}</option>))
                : <React.Fragment></React.Fragment>
            }

        </select>
        </React.Fragment>
    )
}

export default SelectLimit;