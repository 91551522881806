import React from 'react';
import { getStopButtonId } from '../../utils/DataRowButtonUtils';

/**
 * props:
 * - id
 * - title
 * - onClick
 * - showButton
 */
function RowStopButton(props) {
    return (
        <React.Fragment>
            {
                props.showButton || props.showButton === undefined
                    ? <button id={getStopButtonId(props.id)} type='button' className='btn btn-danger btn-xs ms-2' title={props.title} onClick={props.onClick}>
                        <svg className="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 10a1 1 0 011-1h4a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 01-1-1v-4z"></path></svg>
                    </button>
                    : <React.Fragment></React.Fragment>
            }
        </React.Fragment>
    )
}

export default RowStopButton;