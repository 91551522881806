import { getEnvironment } from "../services/EnvironmentStorage";

function hasValue(value) {
    return (value !== null && value !== undefined);
}

function prepareKey(key) {
    const environmentName = getEnvironment();
    return `${environmentName}-${key}`;
}

export function getStoredValue(key, defaultValue) {
    key = prepareKey(key);
    let value = localStorage.getItem(key);

    if (!hasValue(value) && hasValue(defaultValue)) {
        value = defaultValue;
        setStoredValue(key, value);
    }

    return value;
}

export function setStoredValue(key, value) {
    key = prepareKey(key);
    localStorage.setItem(key, value);
}

export function removeStoredValue(key) {
    key = prepareKey(key);
    localStorage.removeItem(key);
}

/*
function setSelected(idSelect, value) {
    let element = document.getElementById(idSelect);
    if (!element) return;

    console.log('setSelected=' + value);
    // console.log(element.value);
    for (var i = 0; i < element.options.length; ++i) {
        if (element.options[i].text === value)
            element.options[i].selected = true;
        else
            element.options[i].selected = false;
    }
    element.value = value;
    selectRef.current.value = value;
}
*/
