import { getBaseUrl } from './ServiceUtils';
import appRequest from './appRequest';

const LOGS_URL = getBaseUrl('/logs/');

export async function getLogs(file) {
    return await appRequest.doGetRequest(`${LOGS_URL}${file}`);
}

export async function getLogsList(userId, page) {
    return await appRequest.doGetRequest(`${LOGS_URL}?page=${page}&userId=${userId ? userId : ''}`);
    /*
    return new Promise((resolve, reject) => {
        const logsList = ['M_1.log', 'M_2.log'];
        resolve({rows: logsList, count: logsList.length});
    });
    */
}