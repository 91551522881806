import axios from 'axios';
import { getToken } from './TokenStorage';

axios.interceptors.request.use(
    config => {
        const token = getToken();
        if (token)
            config.headers.Authorization = token; 
        return config;
    },
    error => Promise.reject(error)
)

// OBS: DESATIVADO POIS ESTE INTERCEPTOR ESTAVA GERANDO ERRO NOS CASOS QUE PRECISA PEGAR dados de requests que retornam com status <> 200, EX: status 400 e retorno com o json de erro da binance
// erro que ocorre: Cannot read properties of undefined (reading 'data')
/*
axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response && (error.response.status === 401 || error.response.status === 403)){
            // 401 = usuario não autorizado
            // 403 = usuário autorizado, mas sem permissão para operação
            console.log(`Redirected to login by 40x response!`);
            window.location = '/';
        }
        else {
            Promise.reject(error);
        }
    }
)
*/

export default axios;
