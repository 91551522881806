import React, { useEffect, useState } from 'react';
// import useWebSocket from 'react-use-websocket';
// import { getEmptyMessage, messageTypes, processWebSocketError } from '../../utils/MessageUtils';
import { getEmptyMessage, messageTypes } from '../../utils/MessageUtils';
import AppWebSocket from '../AppWebSocket/AppWebSocket';

/**
 * props:
 * - type
 * - text
 * - listenMessages
 */
function Toast(props) {
    const DEFAULT_NOTIFICATION = getEmptyMessage();

    const [notification, setNotification] = useState(DEFAULT_NOTIFICATION);

    /*
    function isStringJson(value) {
        if (!value) return false;
        return typeof value === 'string' && value.startsWith('{') && value.endsWith('}');
    }

    function getValue(value) {
        return isStringJson(value) ? JSON.parse(value) : value;
    }*/

    useEffect(() => {
        if (!notification.text) return;

        const notyf = new window.Notyf({
            position: {
                x: 'right',
                y: 'top'
            },
            duration: 0, // infinito
            types: [{
                type: messageTypes.INFO,
                background: 'blue',
                dismissible: true
            }, {
                type: messageTypes.ERROR,
                background: 'red',
                dismissible: true
            }, {
                type: messageTypes.SUCCESS,
                background: 'green',
                dismissible: true
            }]
        });
        notyf.open({
            type: notification.type,
            message: notification.text
        });

        setNotification(DEFAULT_NOTIFICATION);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notification]);

    useEffect(() => {
        setNotification({ type: props.type, text: props.text });
    }, [props.type, props.text]);

    /*
    const { lastJsonMessage } = useWebSocket(process.env.REACT_APP_WS_URL, {
        onOpen: () => console.log('Connected to App WS'),
        onMessage: () => {
            // let lastJsonValue = getValue(lastJsonMessage);
            if (lastJsonMessage && lastJsonMessage.notification) {
                setNotification(lastJsonMessage.notification);
            }
        },
        queryParams: { 'token': getToken() },
        onError: (event) => setNotification(processWebSocketError(event)),
        shouldReconnect: (closeEvent) => true,
        reconnectInterval: 3000
    });
    */

    function onAppNotification(notification) {
        setNotification(notification);
    }

    return (        
        <React.Fragment>
            {
                props.listenMessages === false
                ? <React.Fragment></React.Fragment>
                : (<AppWebSocket onNotification={onAppNotification} />)
            }
        </React.Fragment>
    )
}

export default Toast;