const BRAZILIAN_THOUSANDS_SEPARATOR = '.';
const BRAZILIAN_DECIMAL_SEPARATOR = ',';
const AMERICAN_DECIMAL_SEPARATOR = '.';

function formatIntegerPart(number) {
    if (number === '0') return number;
    
    number = number.replace(/^0+/, '');
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, BRAZILIAN_THOUSANDS_SEPARATOR);
}

export function formatNumber(number, decimalPlaces) {
    if (!number) return number;

    // console.log('formatNumber0='+number);
    if (typeof number == 'number')
        number = number.toString();

    // console.log('formatNumber1='+number);
    const hasDecimalSeparator = number.includes(AMERICAN_DECIMAL_SEPARATOR);

    if (!hasDecimalSeparator) {
        number = formatIntegerPart(number);
        number += decimalPlaces ? BRAZILIAN_DECIMAL_SEPARATOR + '0'.repeat(decimalPlaces) : '';
        return number;
    }

    let [integerPart, decimalPart] = number.split(AMERICAN_DECIMAL_SEPARATOR);
    integerPart = formatIntegerPart(integerPart); 
    decimalPart = decimalPlaces ? decimalPart.substring(0, decimalPlaces) : decimalPart.replace(/0+$/, ''); //decimalPart.trimEnd('0');

    number = decimalPart.length > 0 ? integerPart + BRAZILIAN_DECIMAL_SEPARATOR + decimalPart : integerPart;
    // console.log('formatNumber2='+number);
   
    return number;
}

export function forceAmericanDecimalSeparator(value, defaultValue = '') {
    return value ? value.replace(BRAZILIAN_DECIMAL_SEPARATOR, AMERICAN_DECIMAL_SEPARATOR) : defaultValue;
}

export function getFloatValue(value) {
    return typeof value === 'string' ? parseFloat(forceAmericanDecimalSeparator(value)) : value;
}

export function checkFloatValue(value) {
    const regex = /^(\d+([,.]\d+)?)$/;
    if (typeof value === 'string' && regex.test(value))
        return parseFloat(forceAmericanDecimalSeparator(value));
    else
        return value;
}

