import { getBaseUrl } from './ServiceUtils';
import appRequest from './appRequest';

const SYMBOLS_URL = getBaseUrl('/symbols/');

export async function getSymbols(onlyFavorites = false) {
    const url = onlyFavorites ? `${SYMBOLS_URL}?onlyFavorites=${onlyFavorites}` : SYMBOLS_URL;
    return await appRequest.doGetRequest(url);
}

export async function searchSymbols(search, page) {
    const pageSize = 20;
    return await appRequest.doGetRequest(`${SYMBOLS_URL}?search=${search}&page=${page}&pageSize=${pageSize}`);
}

export async function getSymbol(symbol) {
    return await appRequest.doGetRequest(`${SYMBOLS_URL}${symbol}`);
}

export async function updateSymbol(symbol) {
    return await appRequest.doPatchRequest(`${SYMBOLS_URL}${symbol.symbol}`, symbol);
}

export async function syncSymbols() {
    return await appRequest.doPostRequest(`${SYMBOLS_URL}sync`, {});
}