import { getBaseUrl } from './ServiceUtils';
import appRequest from './appRequest';
import { getDefaultFiat } from '../utils/FiatUtils';

const EXCHANGE_URL = getBaseUrl('/exchange/');

// export const STOP_TYPES = ["STOP_LOSS", "STOP_LOSS_LIMIT", "TAKE_PROFIT", "TAKE_PROFIT_LIMIT"];
// export const FINISHED_STATUS = ["FILLED", "REJECTED", "CANCELED"];

export async function getBalance() {
    return await appRequest.doGetRequest(`${EXCHANGE_URL}balance/` + getDefaultFiat());
}

export async function getFullBalance(fiat) {
    return await appRequest.doGetRequest(`${EXCHANGE_URL}balance/full/` + fiat);
}

export async function getCoins() {
    return await appRequest.doGetRequest(`${EXCHANGE_URL}coins`);
}

export async function getAccountInfo() {
    return await appRequest.doGetRequest(`${EXCHANGE_URL}account-info`);
}

export async function doWithdraw(withdrawTemplateId) {
    return await appRequest.doPostRequest(`${EXCHANGE_URL}withdraw/${withdrawTemplateId}`, null);
}

export async function doTestApiUrl(apiUrl, accessKey, secretKey) {
    const data = { apiUrl, accessKey, secretKey };
    return await appRequest.doPostRequest(`${EXCHANGE_URL}test-api-url`, data);
}

export async function doTestStreamUrl(streamUrl) {
    const data = { streamUrl };
    return await appRequest.doPostRequest(`${EXCHANGE_URL}test-stream-url`, data);
}

