import React, { useState, useEffect, useMemo } from 'react';
import './Dashboard.css';

/**
 * props:
 * - symbol
 */
function CandleChart(props) {

    // eslint-disable-next-line no-unused-vars
    const [widget, setWidget] = useState({});

    useEffect(() => {

        //  pode gerar em https://br.tradingview.com/widget/advanced-chart/
        const w = new window.TradingView.widget({
            symbol: 'BINANCE:' + props.symbol,
            autosize: true,
            interval: "1", // 1 minuto
            timezone: "America/Sao_Paulo", // "Etc/UTC",
            theme: "dark", // ou light
            style: "1",
            locale: "en", // ingles
            toolbar_bg: "#f1f3f6",
            hide_side_toolbar: false,
            enable_publishing: false,
            allow_symbol_change: false,
            details: true,
            withdateranges: true,
            studies: [
                "RSI@tv-basicstudies"
            ],
            container_id: "tradingview"
        });

        setWidget(w);

    }, [props.symbol])

    const widgetHtml = useMemo(() => (
        <div className='row'>
            <div className='col-12 mb-4'>
                <div className='card cardDark border-0 shadow'>
                    <div className='tradingview-widget-container'>
                        <div id="tradingview" className='divTradingView'></div>
                    </div>
                </div>
            </div>
        </div>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [props.symbol]);

    return widgetHtml;
}

export default CandleChart;