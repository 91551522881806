import React from "react";

function BeholderButton(props) {
    return (
        <button id='btnBeholder' title='Beholder Logs' className='btn btn-primary animate-up-2 me-2' data-bs-toggle='modal' data-bs-target='#modalBeholder' onClick={props.onClick}>
            <img src="/img/favicon/favicon-32x32.png" height="20" width="32" alt="Beholder Logo" />
            {/* <span className="ms-2">Beholder Logs</span> */}
        </button>
    )
}

export default BeholderButton;