export function getEnvironment() {
    return localStorage.getItem("environment");
}

export function setEnvironment(environment) {
    localStorage.setItem('environment', environment);
}

export function removeEnvironment() {
    localStorage.removeItem('environment');
}
