export function processError(error) {
    checkErrorRedirectHome(error);
    return printErrorMessage(error);
}

function checkErrorRedirectHome(error) {
    const isErrorRedirectHome = (error.response && error.response.status === 401);

    if (isErrorRedirectHome) {
        console.log(`Redirected to login by 401!`);
        window.location = '/';
    }

    return isErrorRedirectHome;
}

function printErrorMessage(error) {
    const errorMessage = error.response ? error.response.data : error.message;
    console.error(errorMessage);
    return errorMessage;
}

export function getAuthHeaders(token) {
    const headers = { 'authorization': token };
    return { headers };    
}

export function getBaseUrl(path) {
    let apiUrl = process.env.REACT_APP_API_URL;
    if (path)
        apiUrl = `${apiUrl}${path}`; 
    return apiUrl;
}
