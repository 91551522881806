import React, { useRef, useState, useEffect } from 'react';
import ActionMessage from '../../../components/ActionMessage/ActionMessage';
import SwitchInput from '../../../components/SwitchInput/SwitchInput';
import { processError } from '../../../services/ServiceUtils';
import { resetUserPassword, saveUser } from '../../../services/UsersService';
import { getValueOrDefault, initializeModal } from '../../../utils/ModalUtils';
import SelectLimit from './SelectLimit';

/**
 * props:
 * - data
 * - onSubmit
 */
function UserModal(props) {

    const DEFAULT_USER = {
        id: 0,
        name: '',
        email: '',
        password: '',
        limitId: 0,
        telegramChat: '',
        phone: '',
        isActive: false
    };

    const [error, setError] = useState('');
    const [user, setUser] = useState(DEFAULT_USER);

    const btnClose = useRef('');
    const btnSave = useRef('');

    useEffect(() => {
        setUser(props.data);
    }, [props.data]);

    useEffect(() => {
        initializeModal('modalUser', () => setUser({ ...DEFAULT_USER }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onSubmit(event) {
        saveUser(user.id, user)
            .then(result => {
                btnClose.current.click();
                if (props.onSubmit) props.onSubmit(result);
            })
            .catch(err => setError(processError(err)));
    }

    function onResetClick(event) {
        resetUserPassword(user.id)        
        .then(result => {
            btnClose.current.click();
            if (props.onSubmit) props.onSubmit(result);
        })
        .catch(err => setError(processError(err)));
    }

    function onInputChange(event) {
        setUser(prevState => ({ ...prevState, [event.target.id]: event.target.value }));
    }

    return (
        <div className="modal fade" id="modalUser" tabIndex="-1" role="dialog" aria-labelledby="modalTitleNotify" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <p className="modal-title" id="modalTitleNotify">{props.data.id ? 'Edit ' : 'New '}User</p>
                        <button ref={btnClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
                    </div>

                    <div className="modal-body">
                        <div className="form-group">
                            <div className='row'>
                                <div className='col-md-12 mb-3'>
                                    <div className='form-group'>
                                        <label htmlFor='name'>Name:</label>
                                        <input type='text' id='name' className='form-control' onChange={onInputChange} value={getValueOrDefault(user.name)} placeholder="User's full name" />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12 mb-3'>
                                    <div className='form-group'>
                                        <label htmlFor='email'>Email:</label>
                                        <input type='text' id='email' className='form-control' onChange={onInputChange} value={getValueOrDefault(user.email)} placeholder="user@email.com" />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 mb-3'>
                                    <div className='form-group'>
                                        <label htmlFor='phone'>Cellphone:</label>
                                        <input type='text' id='phone' className='form-control' onChange={onInputChange} value={getValueOrDefault(user.phone)} placeholder="+5551123456789" />
                                    </div>
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <div className='form-group'>
                                        <label htmlFor='telegramChat'>Telegram Chat ID: <a className='badge bg-secondary py-1' href='/telegram-chat' target='_blank'>?</a></label>
                                        <input type='text' id='telegramChat' className='form-control' onChange={onInputChange} value={getValueOrDefault(user.telegramChat)} placeholder="123456789" />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 mb-3'>
                                    <div className='form-group'>
                                        <label htmlFor='limit'>Limit:</label>
                                        <SelectLimit id={user.limitId} onChange={onInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 mb-3'>
                                    <div className='form-group'>
                                        <SwitchInput id='isActive' text='Is Active?' onChange={onInputChange} isChecked={user.isActive} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <ActionMessage error={error} extraClassName="mt-1 col-9 py-1" />
                            {
                                user.id > 0
                                    ? (
                                        <button type="button" className="btn btn-sm btn-secondary" onClick={onResetClick}>
                                            <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 8a6 6 0 01-7.743 5.743L10 14l-1 1-1 1H6v2H2v-4l4.257-4.257A6 6 0 1118 8zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 102 0 4 4 0 00-4-4z" clipRule="evenodd" /></svg>
                                            Reset password
                                        </button>
                                    )
                                    : <React.Fragment></React.Fragment>
                            }
                            <button ref={btnSave} type="button" className="btn btn-sm btn-primary" onClick={onSubmit}>
                                <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" /></svg>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default UserModal;