import { getBaseUrl } from './ServiceUtils';
import appRequest from './appRequest';

const USERS_URL = getBaseUrl('/users/');

export async function getUsers(search, page) {
    return await appRequest.doGetRequest(`${USERS_URL}${search ? search : ''}?page=${page}`);

    /* MOCK
    let users = [{
        id: 1,
        name: 'Fabrício',
        email: 'fwendt@gmail.com',
        limitId: 1,
        limit: {
            name: 'Gold'
        },
        isActive: true
    }, {
        id: 2,
        name: 'Viégas',
        email: 'viegas@gmail.com',
        limitId: 2,
        limit: {
            name: 'Silver'
        },
        isActive: false
    }];

    if (search)
        users = users.filter(u => u.name.indexOf(search) !== -1);

    return new Promise((resolve, reject) => {
        return resolve({
            rows: users,
            count: users.length
        });
    });
    */
}

export async function getActiveUsers() {
    return await appRequest.doGetRequest(`${USERS_URL}active`);
}

export async function saveUser(id, newUser) {
    if (id)
        return await appRequest.doPatchRequest(`${USERS_URL}${id}`, newUser);
    else
        return await appRequest.doPostRequest(USERS_URL, newUser);

    // MOCK    
    // return new Promise((resolve, reject) => {
    //     return resolve({});
    // });
}

export async function deleteUser(id) {
    return await appRequest.doDeleteRequest(`${USERS_URL}${id}`);

    // MOCK
    // return new Promise((resolve, reject) => {
    //     return resolve({});
    // });
}

export async function startUser(id) {
    return await appRequest.doPostRequest(`${USERS_URL}${id}/start`, {});

    // MOCK
    // return new Promise((resolve, reject) => {
    //     return resolve({});
    // });
}

export async function stopUser(id) {
    return await appRequest.doPostRequest(`${USERS_URL}${id}/stop`, {});

    // MOCK
    // return new Promise((resolve, reject) => {
    //     return resolve({});
    // });
}

export async function resetUserPassword(id) {
    return await appRequest.doPostRequest(`${USERS_URL}${id}/reset`, {});

    // MOCK
    // return new Promise((resolve, reject) => {
    //     return resolve({});
    // });
}