function initializeModal(modalId, callbackOnClose, callBackOnShow) {
    const modal = document.getElementById(modalId);

    // hidden.bs.modal e show.bs.modal : eventos do bootstrap

    modal.addEventListener('hidden.bs.modal', (event) => {
        if (callbackOnClose) callbackOnClose();
    });

    modal.addEventListener('show.bs.modal', (event) => {
        if (callBackOnShow) callBackOnShow();
    });
}

function isModalOpen(modalId) {
    const modal = document.getElementById(modalId);
    if (!modal) return false;

    return modal.classList.contains('in');
}

function getValueOrDefault(value, defaultValue = '') {
    return value ? value : defaultValue;
}

module.exports = {
    initializeModal,
    isModalOpen,
    getValueOrDefault
}
