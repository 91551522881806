import React, { useEffect, useState, useRef } from 'react';
// import ActionMessage from '../../components/ActionMessage/ActionMessage';
import { initializeModal } from '../../utils/ModalUtils';
import { DEFAULT_SYMBOL } from '../../utils/SymbolUtils';

/**
 * props:
 * - data
 */
function SymbolModal(props) {

    const INITIAL_SYMBOL = {
        symbol: '',
        basePrecision: 0,
        quotePrecision: 0,
        minNotional: '',
        minLotSize: '',
        tickSize: '',
        stepSize: ''
    };

    const btnClose = useRef('');

    // const [error, setError] = useState('');

    const [symbol, setSymbol] = useState(INITIAL_SYMBOL);

    useEffect(() => {
        if (!props.data) return;

        setSymbol(props.data);
    }, [props.data]);

    useEffect(() => {
        initializeModal('modalSymbol', () => setSymbol({ ...INITIAL_SYMBOL }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="modal fade" id="modalSymbol" tabIndex="-1" role="dialog" aria-labelledby="modalTitleNotify" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <p className="modal-title" id="modalTitleNotify">View Symbol</p>
                        <button ref={btnClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="py-3">
                            <div className="form-group mb-4">
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <div className="form-group mb-4">
                                            <label htmlFor="symbol">Symbol</label>
                                            <div className="input-group">
                                                <input className="form-control" id="symbol" type="text" placeholder={DEFAULT_SYMBOL} defaultValue={symbol.symbol} required disabled />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <b>Base Precision: </b>{symbol.basePrecision}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <b>Quote Precision: </b>{symbol.quotePrecision}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <b>Min Notional: </b>{symbol.minNotional}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <b>Min Lot Size: </b>{symbol.minLotSize}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <b>Tick Size: </b>{symbol.tickSize}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <b>Step Size: </b>{symbol.stepSize}
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    {/* <ActionMessage error={error} /> */}
                </div>

            </div>
        </div>
    )

}

export default SymbolModal;