import { getStoredValue, setStoredValue } from "./StorageUtils";

const HOME_KEY = 'homePage';

export const HOME_DASHBOARD = 'dashboard';
export const HOME_REPORTS = 'reports';

const DEFAULT_HOME_PATH = '/dashboard';
const paths = {
    [HOME_DASHBOARD]: DEFAULT_HOME_PATH,
    [HOME_REPORTS]: '/reports'
}

export function setHomePage(homeName) {
    setStoredValue(HOME_KEY, homeName);
}

export function isCurrentHomePage(homeName) {
    return getStoredValue(HOME_KEY) === homeName;
}

export function getPathHomePage() {
    const homeName = getStoredValue(HOME_KEY, HOME_DASHBOARD);
    const pathHomePage = paths[homeName];
    return pathHomePage || DEFAULT_HOME_PATH;
}

