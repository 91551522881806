import React, {useState, useEffect} from 'react';

/**
 * props:
 * - success
 * - error
 * - extraClassName
 */
function ActionMessage(props) {

    const extraClassName = props.extraClassName ? props.extraClassName : '';
    let errorClassName = 'alert alert-danger';
    let successClassName = 'alert alert-success';

    if (extraClassName !== '') {
        errorClassName += ' ' + extraClassName;
        successClassName += ' ' + extraClassName;
    }

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    useEffect(() => {
        // if (!props.error) return;
        setError(props.error);

    }, [props.error]);

    useEffect(() => {
        // if (!props.success) return;
        setSuccess(props.success);

    }, [props.success]);

    return (
        <React.Fragment>
            {
                error ?
                    <div className={errorClassName}>{error}</div>
                    : <React.Fragment></React.Fragment>
            }
            {
                success ?
                    <div className={successClassName}>{success}</div>
                    : <React.Fragment></React.Fragment>
            }
        </React.Fragment>
    )
}

export default ActionMessage;