export const actionButtonTypes = {
    EDIT: 'edit',
    DELETE: 'delete',
    START: 'start',
    STOP: 'stop',
    VIEW: 'view',
    FAVORITE: 'favorite',
    RUN: 'run',
    LOGS: 'logs'
}

const ID_SEPARATOR = '__'; // tem que ser dois underscore pois apenas um da problema com a tela de logs (ids sao nomes de arquivo)

export function getActionButtonId(actionType, id) {
    return `${actionType}${ID_SEPARATOR}${id}`;
}

export function getIdFromActionButton(event, convertToInt = true) {
    if (!event.target.id) return;

    // const id = event.target.id.replace(actionButtonTypes.EDIT, '');
    const id = event.target.id.split(ID_SEPARATOR)[1];
    return convertToInt ? parseInt(id) : id;
}

export function findRowFromActionButton(event, records, idName = 'id', convertToInt = true) {
    const defaultValue = null;
    const id = getIdFromActionButton(event, convertToInt);
    if (!id) return defaultValue;
    if (!records || !records.length) return defaultValue;

    const record = records.find(r => r[idName] === id);
    return record ? { ...record } : record;
}

export function getEditButtonId(id) {
    return getActionButtonId(actionButtonTypes.EDIT, id);
}

export function getDeleteButtonId(id) {
    return getActionButtonId(actionButtonTypes.DELETE, id);
}

export function getStartButtonId(id) {
    return getActionButtonId(actionButtonTypes.START, id);
}

export function getStopButtonId(id) {
    return getActionButtonId(actionButtonTypes.STOP, id);
}

export function getViewButtonId(id) {
    return getActionButtonId(actionButtonTypes.VIEW, id);
}

export function getFavoriteButtonId(id) {
    return getActionButtonId(actionButtonTypes.FAVORITE, id);
}

export function getRunButtonId(id) {
    return getActionButtonId(actionButtonTypes.RUN, id);
}

export function getLogButtonId(id) {
    return getActionButtonId(actionButtonTypes.LOGS, id);
}
