import React, { useEffect, useRef } from 'react';
import { getEventObject } from '../../utils/EventUtils';

/**
 * props:
 * - id
 * - text
 * - isChecked
 * - onChange
 */

function SwitchInput(props) {

    const switchRef = useRef('');

    function onChange(event) {
        props.onChange(getEventObject(props.id, switchRef.current.checked));
    }

    useEffect(() => {
        switchRef.current.checked = props.isChecked;

    }, [props.isChecked])

    return (
        <div>
            <div className='form-check form-switch'>
                <input ref={switchRef} className='form-check-input' type='checkbox' id={props.id} onChange={onChange} />
                <label className='form-check-label' htmlFor={props.id}>{props.text}</label>
            </div>
        </div>
    )
}

export default SwitchInput;