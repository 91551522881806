import React, { useState, useEffect, useMemo } from 'react';
import { getSymbols } from '../../services/SymbolsService';
import { processError } from '../../services/ServiceUtils';
import { getEventObject } from '../../utils/EventUtils';
import Select from 'react-select/creatable';
// import { getQuoteFromWildcard } from '../../utils/SymbolUtils';

/**
 * props:
 * - symbol
 * - disabled
 * - onChange
 * - hideSelectOption
 */
function SelectSymbol(props) {

    const [symbols, setSymbols] = useState(['LOADING']);
    const [isDisabled, setIsDisabled] = useState(false);
    const [value, setValue] = useState('');

    useEffect(() => {
        setValue(getSelectObject(props.symbol));
    }, [props.symbol]);

    useEffect(() => {
        setIsDisabled(props.disabled);
    }, [props.disabled]);

    useEffect(() => {
        getSymbols()
            .then(symbolObjects => {
                const symbolNames = symbolObjects.rows ? symbolObjects.rows.map(s => s.symbol) : symbolObjects.map(s => s.symbol);

                if (symbolNames.length) {
                    setSymbols(symbolNames.map(s => { return getSelectObject(s) }));
                    // props.onChange(getEventObject('symbol', symbolNames[0])); // ta trocando o symbol inicial do SelectSymbol
                }
                else {
                    setSymbols(["NO SYMBOLS"]);
                    setIsDisabled(true);
                }
            })
            .catch(err => {
                processError(err);
                setSymbols(["ERROR"]);
                setIsDisabled(true);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getSelectObject(itemValue) {
        return { label: itemValue, value: itemValue };
    }

    function onSymbolChange(event) {
        props.onChange(getEventObject('symbol', event.value));
    }

    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: 220
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 })
    };

    const selectSymbol = useMemo(() => {
        return (
            <Select 
                value={value}
                isDisabled={isDisabled}
                styles={customStyles}
                onChange={onSymbolChange}
                options={symbols}
                menuPortalTarget={document.body} 
            />
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [symbols, value, isDisabled]);

    return (selectSymbol)
}

export default SelectSymbol;