import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import { processError } from '../../services/ServiceUtils';
import Toast from '../../components/Toast/Toast';
import LogModal from '../../components/Logs/LogModal';
import { getEmptyMessage, getErrorMessage } from '../../utils/MessageUtils';
import { getLogsList } from '../../services/LogsService';
import LogRow from './LogRow';
import BeholderButton from './Beholder/BeholderButton';
import BeholderModal from './Beholder/BeholderModal';
import SelectUser from './SelectUser';
import Pagination from '../../components/Pagination/Pagination';
import { getIdFromActionButton } from '../../utils/DataRowButtonUtils';

function Logs() {

    const defaultLocation = useLocation();
    const history = useHistory();

    function getPage(location) {
        if (!location) location = defaultLocation;
        return new URLSearchParams(location.search).get('page');
    }

    useEffect(() => {
        return history.listen(location => {
            setPage(getPage(location));
        });
    }, [history]); // eslint-disable-line react-hooks/exhaustive-deps

    const [count, setCount] = useState(0);
    const [page, setPage] = useState(getPage());
    const [logs, setLogs] = useState([]);

    const [userId, setUserId] = useState('');

    const [notification, setNotification] = useState(getEmptyMessage());

    const [viewLog, setViewLog] = useState('');

    useEffect(() => {
        getLogsList(userId, page || 1)
            .then(result => {
                setLogs(result.rows);
                setCount(result.count);
            })
            .catch(err => setNotification(getErrorMessage(processError(err))))
    }, [page, userId]);

    function onViewClick(event) {
        const file = getIdFromActionButton(event, false);
        console.log(file);
        // eslint-disable-next-line eqeqeq
        setViewLog(file.replace('.log', ''));
    }

    function onUserChange(event) {
        setUserId(event.target.value);
    }

    return (
        <React.Fragment>
            <Menu />
            <main className='content'>
                <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
                    <div className='d-block mb-4 mb-md-0'>
                        <h2 className='h4'>Logs</h2>
                    </div>
                    <div className='btn-toolbar mb-4 mb-md-0'>
                        <div className='d-inline-flex align-items-center'>
                            <BeholderButton />
                            <SelectUser onChange={onUserChange} />
                        </div>
                    </div>
                </div>
                <div className='card card-body border-0 shadow table-wrapper table-responsive'>
                    <table className='table table-hover'>
                        <thead>
                            <tr>
                                <th className='border-gray-200'>Log file</th>
                                <th className='border-gray-200'>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                logs && logs.length
                                ? logs.map(log => (
                                    <LogRow key={log} data={log} onViewClick={onViewClick} />
                                ))
                                : <React.Fragment></React.Fragment>
                            }
                        </tbody>
                    </table>
                    <Pagination count={count} />
                </div>
                <Footer />
            </main>
            <BeholderModal />
            <LogModal file={viewLog} />
            <Toast type={notification.type} text={notification.text} />
        </React.Fragment>
    )
}

export default Logs;