import React, { useState, useEffect } from 'react';
import { processError } from '../../services/ServiceUtils';
import { getActiveUsers } from '../../services/UsersService';

/**
 * props:
 * - onChange
 */
function SelectQuote(props) {

    const [users, setUsers] = useState([]);

    useEffect(() => {
        getActiveUsers()
            .then(result => setUsers(result))
            .catch(err => {
                processError(err);
                setUsers(['ERROR']);
            });
    }, []);

    return (
        <select id="userId" className="form-select" onChange={props.onChange}>
            <option value=''>All</option>
            {
                users && users.length
                    ? users.map(u => (<option key={u.id} value={u.id}>{u.name}</option>) )
                    : <React.Fragment></React.Fragment>
            }
        </select>
    )
}

export default SelectQuote;