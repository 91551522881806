import React from 'react';

/**
 * props:
 * - symbol
 * - base
 */
function SymbolIcon(props) {
    return (
        <React.Fragment>
            <img 
                src={`/img/icons/black/${props.base ? props.base.toLowerCase() : 'kkk'}.svg`} 
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src="/img/question.svg";
                  }}                                
                className="me-2" width={16} alt='' />
            {props.symbol}
        </React.Fragment>

    )
}

export default SymbolIcon;