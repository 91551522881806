import React from 'react';
import RowDeleteButton from '../../components/ActionButtons/RowDeleteButton';
import RowEditButton from '../../components/ActionButtons/RowEditButton';
import RowStartButton from '../../components/ActionButtons/RowStartButton';
import RowStopButton from '../../components/ActionButtons/RowStopButton';

/**
 * props:
 * - data
 * - onEditClick
 * - onStopClick
 * - onStartClick
 * - onDeleteClick
 */
function UserRow(props) {

    return (
        <tr>
            <td>
                {
                    props.data.isActive
                        ? <svg className="icon icon-xs me-2" fill="green" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" /></svg>
                        : <svg className="icon icon-xs me-2" fill="red" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" /></svg>
                }
                {props.data.name}
            </td>
            <td>
                {props.data.email}
            </td>
            <td>
                {props.data.limit.name}
            </td>
            <td>
                <RowEditButton id={props.data.id} title='Edit this User' modalId='modalUser' onClick={props.onEditClick} />
                <RowStopButton id={props.data.id} title='Stop this User' onClick={props.onStopClick} showButton={props.data.isActive} />
                <RowStartButton id={props.data.id} title='Start this User' onClick={props.onStartClick} showButton={!props.data.isActive} />
                <RowDeleteButton id={props.data.id} title='Delete this User' onClick={props.onDeleteClick} showButton={!props.data.isActive} />
            </td>
        </tr>
    );
}

export default UserRow;