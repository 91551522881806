import React, { useMemo } from "react";
import { formatNumber } from "../../utils/NumberUtils";

/**
 * props:
 * - title
 * - value
 * - prefix
 * - precision
 * - background
 */
function InfoBlock(props) {

    function getBackground() {
        const color = props.background ? props.background : 'primary';
        return `icon-shape icon-shape-${color} rounded me-4 me-sm-0`;
    }

    function getValueText() {
        if (typeof props.value === 'string') return props.value;
        
        const precision = props.precision !== undefined ? parseInt(props.precision) : 2;
        if (!props.value) return 0;
        let value = parseFloat(props.value);
        if (value === undefined || value === null || !isFinite(value)) return 0;

        if (value > 1000000) return `${(value / 1000000).toFixed(precision)}M`;
        if (value > 1000) return `${(value / 1000).toFixed(precision)}k`;
        value = value.toFixed(precision);
        value = formatNumber(value, precision);

        const prefix = props.prefix ? props.prefix + ' ' : '';
        return prefix + value;
    }

    const infoBlock = useMemo(() => {
        return (
            <div className="col-md-4 col-sm-6 mb-4">
                <div className="card border-0 shadow">
                    <div className="card-body">
                        <div className="row d-block d-xl-flex align-items-center">
                            <div className="col-12 d-flex">
                                <div className={getBackground()}>
                                    {props.children}
                                </div>
                                <div className="ms-3">
                                    <h2 className="h5">{props.title}</h2>
                                    <h3 className="fw-extrabold">{getValueText()}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, [props.value]);

    return infoBlock;
}

export default InfoBlock;