import { getBaseUrl } from './ServiceUtils';
import appRequest from './appRequest';

const API_SETTINGS_URL = getBaseUrl('/settings/');

export async function getSettings() {
    return await appRequest.doGetRequest(API_SETTINGS_URL);
}

export async function updateSettings(settings) {
    return await appRequest.doPatchRequest(API_SETTINGS_URL, settings);
}

export async function doTestEmail() {
    return await appRequest.doGetRequest(`${API_SETTINGS_URL}testEmail`);
}

export async function doTestSms() {
    return await appRequest.doGetRequest(`${API_SETTINGS_URL}testSms`);
}

export async function doTestTelegram() {
    return await appRequest.doGetRequest(`${API_SETTINGS_URL}testTelegram`);
}