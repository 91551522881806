import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';
import Pagination from '../../components/Pagination/Pagination';
import Footer from '../../components/Footer/Footer';
import { getLimits, startLimit, stopLimit, deleteLimit } from '../../services/LimitsService';
import { processError } from '../../services/ServiceUtils';
import LimitRow from './LimitRow';
import Toast from '../../components/Toast/Toast';
import NewLimitButton from './NewLimitButton';
import { getEmptyMessage, getErrorMessage } from '../../utils/MessageUtils';
import LimitModal from './LimitModal';
import { findRowFromActionButton, getIdFromActionButton } from '../../utils/DataRowButtonUtils';

function Limits() {

    const defaultLocation = useLocation();
    const history = useHistory();

    function getPage(location) {
        if (!location) location = defaultLocation;
        return new URLSearchParams(location.search).get('page');
    }

    useEffect(() => {
        return history.listen(location => {
            setPage(getPage(location));
        });
    }, [history]); // eslint-disable-line react-hooks/exhaustive-deps

    const [count, setCount] = useState(0);
    const [page, setPage] = useState(getPage());
    const [limits, setLimits] = useState([]);

    const [notification, setNotification] = useState(getEmptyMessage());

    const [editLimit, setEditLimit] = useState({});

    useEffect(() => {
        getLimits(page || 1)
            .then(result => {
                setLimits(result.rows);
                setCount(result.count);
            })
            .catch(err => setNotification(getErrorMessage(processError(err))))
    }, [page])

    function onEditClick(event) {
        setEditLimit(findRowFromActionButton(event, limits));
    }

    function onStopClick(event) {
        stopLimit(getIdFromActionButton(event))
            .then(result => { history.go(0) })
            .catch(err => setNotification(getErrorMessage(processError(err))))
    }

    function onStartClick(event) {
        startLimit(getIdFromActionButton(event))
            .then(result => { history.go(0) })
            .catch(err => setNotification(getErrorMessage(processError(err))))
    }

    function onDeleteClick(event) {
        deleteLimit(getIdFromActionButton(event))
            .then(result => { history.go(0) })
            .catch(err => setNotification(getErrorMessage(processError(err))))
    }

    function onLimitSubmit(event) {
        history.go(0);
    }

    return (
        <React.Fragment>
            <Menu />
            <main className='content'>
                <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
                    <div className='d-block mb-4 mb-md-0'>
                        <h2 className='h4'>Limits</h2>
                    </div>
                    <div className='btn-toolbar mb-2 mb-md-0'>
                        <div className='d-inline-flex align-items-center'>
                            <NewLimitButton />
                        </div>
                    </div>
                </div>
                <div className='card card-body border-0 shadow table-wrapper table-responsive'>
                    <table className='table table-hover'>
                        <thead>
                            <tr>
                                <th className='border-gray-200'>Name</th>
                                <th className='border-gray-200'>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                limits && limits.length
                                    ? limits.map(limit => (
                                        <LimitRow key={limit.id} data={limit} onEditClick={onEditClick} onStopClick={onStopClick} onStartClick={onStartClick} onDeleteClick={onDeleteClick} />
                                    ))
                                    : <React.Fragment></React.Fragment>
                            }
                        </tbody>
                    </table>
                    <Pagination count={count} />
                </div>
                <Footer />
            </main>
            <LimitModal data={editLimit} onSubmit={onLimitSubmit} />
            <Toast type={notification.type} text={notification.text} />
        </React.Fragment>
    )
}

export default Limits;