import { getBaseUrl } from './ServiceUtils';
import appRequest from './appRequest';

const BEHOLDER_URL = getBaseUrl('/beholder/');

export async function getIndexes() {
    return await appRequest.doGetRequest(`${BEHOLDER_URL}memory/indexes`);
}

export async function getMemoryIndex(symbol, index, interval) {
    return await appRequest.doGetRequest(`${BEHOLDER_URL}memory/${symbol}/${index}/${interval}`);
}

export async function getAnalysisIndexes() {
    return await appRequest.doGetRequest(`${BEHOLDER_URL}analysis`);
}

export async function getMemory() {
    return await appRequest.doGetRequest(`${BEHOLDER_URL}memory`);
}

export async function getAgenda() {
    return await appRequest.doGetRequest(`${BEHOLDER_URL}agenda`);
}

export async function getBrain() {
    return await appRequest.doGetRequest(`${BEHOLDER_URL}brain`);
}
