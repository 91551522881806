import { getBaseUrl } from './ServiceUtils';
import appRequest from './appRequest';

const LIMITS_URL = getBaseUrl('/limits/');

/*
const MOCK_LIMITS = [{
    id: 1,
    name: 'Gold',
    maxAutomations: 5,
    maxMonitors: 4,
    maxBacktests: 3,
    isActive: true
}, {
    id: 2,
    name: 'Silver',
    maxAutomations: 1,
    maxMonitors: 2,
    maxBacktests: 3,
    isActive: false
}];
*/

export async function getLimits(page) {
    return await appRequest.doGetRequest(`${LIMITS_URL}?page=${page}`);

    // return new Promise((resolve, reject) => {
    //     return resolve({
    //         rows: MOCK_LIMITS,
    //         count: MOCK_LIMITS.length
    //     });
    // });
}

export async function getAllLimits() {
    return await appRequest.doGetRequest(`${LIMITS_URL}all`);

    // return new Promise((resolve, reject) => {
    //     return resolve(MOCK_LIMITS);
    // });
}

export async function getActiveLimits() {
    return await appRequest.doGetRequest(`${LIMITS_URL}active`);

    // const activeLimits = MOCK_LIMITS.filter(l => l.isActive);

    // return new Promise((resolve, reject) => {
    //     return resolve(activeLimits);
    // });
}

export async function saveLimit(id, newLimit) {
    if (id)
        return await appRequest.doPatchRequest(`${LIMITS_URL}${id}`, newLimit);
    else
        return await appRequest.doPostRequest(LIMITS_URL, newLimit);

    // return new Promise((resolve, reject) => {
    //     return resolve({});
    // });
}

export async function deleteLimit(id) {
    return await appRequest.doDeleteRequest(`${LIMITS_URL}${id}`);
    // return new Promise((resolve, reject) => {
    //     return resolve({});
    // });
}

export async function startLimit(id) {
    return await appRequest.doPatchRequest(`${LIMITS_URL}${id}`, { isActive: true });
    // return new Promise((resolve, reject) => {
    //     return resolve({});
    // });
}

export async function stopLimit(id) {
    return await appRequest.doPatchRequest(`${LIMITS_URL}${id}`, { isActive: false });
    // return new Promise((resolve, reject) => {
    //     return resolve({});
    // });
}
