import React from 'react';
import useWebSocket from 'react-use-websocket';
import { getToken } from '../../services/TokenStorage';
import { processWebSocketError } from '../../utils/MessageUtils';

/**
 * props:
 * - onMessage 
 * - onNotification
 */
function AppWebSocket(props) {

    const { lastJsonMessage } = useWebSocket(process.env.REACT_APP_WS_URL, {
        onOpen: () => console.log('Connected to App WS'),
        onMessage: () => {
            if (!lastJsonMessage) return;

            if (lastJsonMessage.notification && props.onNotification)
                props.onNotification(lastJsonMessage.notification);
            else if (props.onMessage)
                props.onMessage(lastJsonMessage);
        },
        queryParams: { 'token': getToken() },
        onError: (event) => {
            // console.log('AppWebsocket');
            // console.log(event);
            const errorNotification = processWebSocketError(event);
            if (props.onNotification)
                props.onNotification(errorNotification);
        },
        shouldReconnect: (closeEvent) => true,
        reconnectInterval: 3000
    });

    return (
        <React.Fragment></React.Fragment>
    )
}

export default AppWebSocket;