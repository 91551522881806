import React from 'react';
import { getEditButtonId } from '../../utils/DataRowButtonUtils';

/**
 * props:
 * - id
 * - title
 * - modalId
 * - onClick
 * - showButton
 */
function RowEditButton(props) {
    return (
        <React.Fragment>
            {
                props.showButton || props.showButton === undefined
                    ? <button id={getEditButtonId(props.id)} type='button' className='btn btn-secondary btn-xs ms-2' title={props.title} data-bs-toggle='modal' data-bs-target={'#' + props.modalId} onClick={props.onClick}>
                        <svg className="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
                    </button>
                    : <React.Fragment></React.Fragment>
            }
        </React.Fragment>
    )
}

export default RowEditButton;