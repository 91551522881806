import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes';

ReactDOM.render(
  <React.StrictMode>
    <Routes />
  </React.StrictMode>,
  document.getElementById('root')
);

// Desenvolvido usando o tema Volt:
// https://demo.themesberg.com/volt/
// https://demo.themesberg.com/volt/pages/dashboard/dashboard.html
